<template>
    <div class=""
        style="width: 100%; height: 100%; position: absolute; "
    >
        <div style="width: 100%; height: 100%; position: relative;">
            <div class="text-center" style="width: 100%; position: absolute; top: 60px;">
                <h1 class="gray-footer2" style="margin-bottom: 0px;"> 404 </h1>
                <h3 class="gray-footer2" style="margin-top: 0px;"> Not Found </h3>
            </div>
            <div class="text-center" style="width: 100%; position: absolute; bottom: 10px;">
                <span class="gray-footer2 " style=" font-size: 22.1px;">BankInvoice Paper</span>
            </div>
        </div>
    </div>
    <div class="" 
        style="display: table; width: 100%;height: 100%; position: absolute;" 
        @mousemove="mousemove"
    >
        <div style="display: table-cell; vertical-align: middle;">
            <Nico :degree="degree"/>
        </div>
    </div>
</template>

<script>
import Nico from '@/components/Nico';

export default {
    name: 'NotFoundPage',
    components: {
        Nico,
    },
    data: function(){
        return {
            degree: 45,
            centerX: window.innerWidth / 2,
            centerY: window.innerHeight / 2,
            parameter: null,

        }
    },
    computed: {
    },
    methods: {
        mousemove: function(e) {
            const rect = e.target.getBoundingClientRect();
            let mouseX = (e.clientX - this.centerX);
            let mouseY = (rect.bottom - e.clientY - this.centerY);
            const degree = Math.atan2(mouseY, mouseX) * 180 / Math.PI
            if(this.parameter === null) {
                this.parameter = -degree - 45 + 90
            }
            this.degree = (degree + this.parameter)
        }
    }
}
</script>

<style scoped>
</style>
